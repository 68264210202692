<!-- 金币账单 -->
<template>
	<div :class="themeClass" class="myPointsContent">
		<div>
			<div class="flex-row-align-center">
				<div style="width: 100%; height: 32px; margin-top: 30px;" class="font-weight700  billtext flex-row font-size18">
					<span>金币账单</span>
					<div v-if="fIsMessage" style="font-size: 14px; font-weight: 400;display: inle-bolck; width: 800px; margin-left: 20px; color: #f88607;">{{goldMessage}}</div>
				</div>
				<!-- <div class="flex-row-align-center date">
					<el-date-picker v-model="value" type="daterange" range-separator="至" :start-placeholder="defaultTime" :end-placeholder="defaultTime"
						 class="font-size13 margin-l-10 chooseDate" value-format="yyyy-MM-dd" :clearable="false">
						</el-date-picker>
				</div> -->
				<!-- <div class="allOrder-btn">
				 <div  class="background-color-theme pointer zad" @click="aaa()">查询</div>
				  <div type="primary" class="background-color-theme ad">execl表格导出</div>
				</div> -->
			</div>
			<div class="flex-row-align-center">
        <div class="choose-date">
          <!-- 新时间组件 -->
          <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
        </div>
				<!-- <span :class="activeName=='yearMonth'?'activeClass':''" style="width: 50px;">
					年月
				</span>
				<el-select  v-model="balance" placeholder="请选择年月" style="width: 140px;margin-right:10px;" @change="monthChange">
					<el-option v-for="(item,index) in balanceOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<div class="tabs">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane class="pointer-color-theme" label="昨天" name="yesterday"></el-tab-pane>
						<el-tab-pane class="pointer-color-theme" label="今天" name="today"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="inputBor margin-r-10">
					<el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期">
					</el-date-picker>
					<span>至</span>
					<el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期">
					</el-date-picker>
				</div> -->
				<div class="allOrder-btn">
					<div class="background-color-theme pointer zad" @click="aaa()">查询</div>
					<div type="primary" class="background-color-theme ad pointer"  @click="ExportEXcel()">账单下载</div>
				</div>


			</div>





			<!-- tab切换 -->
			<div class="tabTitleRow pointer">
				<div v-for="(item,index) in tabData" :key="index" :class="item.checked?'checkTitleSty':''" @click="selecttab(index)">
					{{item.title}}
				</div>
			</div>
			<!-- 统计列表 -->
			<div v-if="listShow == 0" v-loading="listLoading">
				<!-- <div style="display: flex;justify-content: center;">
					<div v-if="tableData.length < 1" class="flex-column-center-center">
						<svg-icon icon-class="NoContent" />
					</div>
					<div v-if="tableData.length >= 1" style="width: 100%;display: flex;flex-wrap:wrap">
						<div class="itemIcontment"  v-for='(item,index) of tableData' :key='index'>
							<div style="margin-left: 20px;"><img style="width: 45px;height: 45px;" :src="item.imgsrc"></img> </div>
							<div style="margin-left: 20px;">
								<div class='chineseDes'>{{item.activityName}}</div>
								<div><span class='coinNumber'>{{item.fChangeCapital}}</span><span class='coincss'>金币</span></div>
							</div>
						</div>
					</div>
				</div> -->
				<div style="display: flex;justify-content: center;">
					<div v-if="tableData.length < 1" class="flex-column-center-center">
						<svg-icon icon-class="NoContent" />
					</div>
					<div v-if="tableData.length >= 1" style="width: 100%;display: flex;flex-wrap:wrap">
						<div class="itemIcontment flex-row-space-between-center"  v-for='(item,index) of tableData' :key='index'>
							<img style="width: 36px;height: 36px;" :src="item.imgsrc"></img>
							<div class='chineseDes'>{{item.activityName}}</div>
							<div style="border-top: 2px dashed #D6F0FF; width:558px;"></div>
							<div style="color:#0173FE;font-weight: 700;font-size: 18px;width:100px; text-align: right;"><span >{{item.fChangeCapital}}</span><span class='coincss' style="margin-left:5px;">金币</span></div>
							
						</div>
					</div>
				</div>
				<div class='sumclass' v-if="tableData.length >= 1">
					<span class='coincss' style="font-size:16px;">合计：</span>
					<span class='coinNumber'>{{allicon}}</span>
					<span class='coincss'>金币</span>
				</div>
			</div>
			<!-- 账单报表 -->
			<div v-else v-loading="tableLoading">
				<div class="tabTwo">
					<el-table :data="tableData1" border style="width: 100%;">
						<el-table-column prop="fActivityName" label="类型"> </el-table-column>
						<el-table-column prop="fModifyTime" label="日期"> </el-table-column>
						<el-table-column prop="fChangeCapital" label="金币"> </el-table-column>
					</el-table>
				</div>
				<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
					<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="page"
					 :pagesize="pagesize" :total="total"></pagination>
				</div>
			</div>
		</div>
		<!-- <div class="flex-row-center-center" v-if="tabData.length == 0">
			<svg-icon icon-class="NoRightsAndInterests" />
		</div> -->
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
  import Timequery from "@/components/Timequery.vue"
	import pagination from '@/components/pagination/pagination.vue';
	export default {
		components: {
			pagination,
      Timequery
		},
		data() {
			return {
				listLoading:false,  //统计报表的加载动画
				tableLoading:false,  //帐单列表
				balance:'',
				balanceOptions: [],
				startMouth:'',
				endMouth:'',
				activeName: "today",
				// searchDate: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 90)), new Date()],
				searchDate: [new Date(new Date().setTime(new Date().getTime())), new Date()],
				value: "",
				listShow: 0,
				maxResultCount: 20,
				page:1,
				total: 0, //总条数
				pagesize: 20, //每页显示条目个数
				currentPage: 1, //当前页
				tableData: [],
				tableData1: [],
				columnList:[],
				defaultTime: '', //时间默认值
				tabData: [{
						title: '统计报表',
						type: 1,
						checked: true,
					},
					{
						title: '账单列表',
						type: 2,
						checked: false
					},
				],
				tablebles: [{
						label: "类型",
						width: "276",
						prop: "fActivityName",
						align: "center",
						fixed: true
					},
					{
						label: "日期",
						width: "100",
						prop: "fModifyTime",
						align: "center",
						fixed: true
					},
					{
						label: "金币",
						width: "100",
						prop: "fChangeCapital",
						align: "center",
						fixed: true
					}
				],
				billDetailsData: [],
				iconArray:[
					'@/assets/jbicon/1.png',
					'@/assets/jbicon/2.png',
					'@/assets/jbicon/3.png',
					'@/assets/jbicon/4.png',
					'@/assets/jbicon/5.png',
					'@/assets/jbicon/6.png',
					'@/assets/jbicon/7.png',
					'@/assets/jbicon/8.png',
					'@/assets/jbicon/9.png',
					'@/assets/jbicon/10.png',
					'@/assets/jbicon/11.png',
					'@/assets/jbicon/12.png',
					'@/assets/jbicon/13.png',
					'@/assets/jbicon/14.png',
					'@/assets/jbicon/15.png',
					'@/assets/jbicon/16.png',
				],//金币账单所有可能出现的
				allicon:'',//合计的金币
				goldMessage: '',
				fIsMessage: 0
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		created() {
			this.getdayValue()
		},
		mounted() {

		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.searchDate[0] = val[0]
			  this.searchDate[1] = val[1]
        // this.acquireMonth();
		  	this.pointBillGetList();
		  	// this.acquireDate();
		   	this.getmessage()
      },
			// 金币到期提醒
			getmessage() {
				this.ApiRequestPost('api/mall/ebbalance/customer-gold-coin/get-my-gold-coin-and-message', {
				}).then(
					result => {
						console.log('111111111111',result)
						this.fIsMessage = result.obj.fIsMessage
						this.goldMessage = result.obj.fMessageGoldCoin
					},
					error => {
					}
				);
			},
			//获取当前时间的前12个月
			dateAcquire(){
				    var dataArr = [];
				    var data = new Date();
				    var year = data.getFullYear();
				    data.setMonth(data.getMonth()+1, 1)//获取到当前月份,设置月份
				    for (var i = 0; i < 12; i++) {
				        data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
				        var m = data.getMonth() + 1;
				        m = m < 10 ? "0" + m : m;
						dataArr.push({
							value: data.getTime(),
							label: data.getFullYear() + "年" + (m) + "月"
						})
				    }
					this.balanceOptions = JSON.parse(JSON.stringify(dataArr))
			},
			//时间更改
			monthChange(e){
				this.activeName = "yearMonth"
				this.searchDate[0] = this.getMonthFirst(e)
				this.searchDate[1] = this.getMonthLast(e)
				this.aaa()
			},
			//格式化时间
			getDateNoDay(time) {
			  if (time == null || time == '') {
			    return '';
			  }
			  let date = new Date(time);
			  let year = date.getFullYear(); //年
			  let month = date.getMonth() + 1; //月
			  let day = date.getDate(); //日
			  month = month > 9 ? month : '0' + month;
			  day = day > 9 ? day : '0' + day;
			  return `${year}年${month}月`;
			},
			//获取某个月的第一天
			getMonthFirst(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth(), 1)
			 	return enddate
			},
			//获取某个月的最后一天
			getMonthLast(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth() + 1, 0)
			 	return enddate
			},
			acquireDate(){
				this.dateAcquire()
			},
			acquireMonth(){
				this.ApiRequestPostNOMess("api/mall/sys/account-date/get-curret-date", {
				}).then(
					(res) => {
					// console.log(res,'89898989898')
					this.startMouth = res.obj.fStartDate
					this.endMouth = res.obj.fEndDate
					},
					(error) => {}
				);
			},
			handleCurrentChange(val) {
				this.page = val;
				this.pointBillGetList1();
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', '']
				}
				const start = new Date();
				const end = new Date();
				this.balance = "";
				switch (this.activeName) {
					case "all":
						this.searchDate[0] = ''
						this.searchDate[1] = ''
						break;
					case "today":
						this.searchDate[0] = start.setTime(start.getTime());
						this.searchDate[1] = end
						break;
					case "yesterday":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24))
						this.searchDate[1] = this.searchDate[0]
						break;
					case "oneMonth":
						this.searchDate[0] = this.startMouth;
						this.searchDate[1] = this.endMouth;
						break;
				}
				this.currentPage = 1;
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				}
			},
			getdayValue() {
				let a = new Date()
				this.defaultTime = this.getDate(a)
			},
			//查询
			aaa() {
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				}
			},
			//tab点击事件
			selecttab(e) {
				console.log(e, '111111');
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				}
			},
			//获取日期的值
			bindChange(e) {
				console.log(e, "e")
				this.value = e;
				// // console.log('gundong',e)
				this.beginTime = this.getDate(this.value[0]);
				this.endTime = this.getDate(this.value[1]);
			},
			//获取账单明细
			pointBillGetList() {
				this.listLoading=true;
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-gold-coin-detail/get-gold-coin-detail-summery-by-customerid", {
						// filter: "",
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						// maxResultCount: this.maxResultCount,
						// skipCount: (this.page - 1) * this.maxResultCount,
						// sorting: "",
					}
				).then(
					(result) => {
						console.log(result, "result1111111111")
						this.tableData = result.obj;
						for (let item of result.obj) {
							item.fCreateTime = this.getTime(item.fCreateTime);
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fEndTime = this.getDate(item.fEndTime);
							item.fBeginTime = this.getDate(item.fBeginTime);
						}
						this.allicon = ''
						for(let item of this.tableData){
							this.allicon = Number(this.allicon) + Number(item.fChangeCapital)
							if(item.activityName == '首次升级奖励'){
								item.imgsrc =  require('@/assets/jbicon/13.png')
							}else if(item.activityName == '邀请奖励'){
								item.imgsrc =  require('@/assets/jbicon/15.png')  
							}else if(item.activityName == '购买金币商城礼品'){
								item.imgsrc =  require('@/assets/jbicon/3.png') 
							}else if(item.activityName == '被邀请首次发货奖励'){
								item.imgsrc =  require('@/assets/jbicon/1.png')
							}else if(item.activityName == '年度扣减'){
								item.imgsrc =  require('@/assets/jbicon/9.png')
							}else if(item.activityName == '积分换金币'){
								item.imgsrc =  require('@/assets/jbicon/5.png')  
							}else if(item.activityName == '账户注销'){
								item.imgsrc =  require('@/assets/jbicon/16.png')  
							}else if(item.activityName == '分享APP奖励'){
								item.imgsrc =  require('@/assets/jbicon/2.png') 
							}else if(item.activityName == '每日签到奖励'){
								item.imgsrc =  require('@/assets/jbicon/8.png')
							}else if(item.activityName == '后台赠送'){
								item.imgsrc =  require('@/assets/jbicon/4.png')
							}else if(item.activityName == '被邀请奖励'){
								item.imgsrc =  require('@/assets/jbicon/15.png') 
							}else if(item.activityName == '邀请首次发货奖励'){
								item.imgsrc =  require('@/assets/jbicon/1.png')
							}else if(item.activityName == '新系统首次登录'){
								item.imgsrc =  require('@/assets/jbicon/14.png')
							}else if(item.activityName == '连续签到奖励'){
								item.imgsrc =  require('@/assets/jbicon/7.png')
							}else if(item.activityName == '平台补偿'){
								item.imgsrc =  require('@/assets/jbicon/10.png') 
							}else if(item.activityName == '客商信息完整'){
								item.imgsrc =  require('@/assets/jbicon/6.png') 
							}else if(item.activityName == '评价订单平台奖励'){
								item.imgsrc =  require('@/assets/jbicon/11.png') 
							}else if(item.activityName == '生日礼包'){
								item.imgsrc =  require('@/assets/jbicon/12.png')
							}
							
						}
						this.listLoading=false;
					},
					(error) => {
						//   alert(JSON.stringify(error));
						this.listLoading=false;
					}
				);
			},
			// 获取账单列表   
			pointBillGetList1() {
				this.tableLoading=true;
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-gold-coin-detail/get-gold-coin-detail-by-customerid", {
						filter: "",
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.maxResultCount,
						skipCount: (this.page - 1) * this.maxResultCount,
						sorting: "",
					}
				).then(
					(result) => {
						// console.log(result,"result333333333333")
						// this.billDetailsData = result.obj.items;
						this.tableData1 = result.obj.items;
						this.total = result.obj.totalCount;
						// console.log(result.obj.items,"22222this.billDetailsData")
						for (let item of result.obj.items) {
							// item.fCreateTime = this.getTime(item.fCreateTime);
							if(item.fModifyTime !=null){
								item.fModifyTime = this.getTime(item.fModifyTime);
							}
							
							// item.fEndTime = this.getDate(item.fEndTime);
							// item.fBeginTime = this.getDate(item.fBeginTime);
						}
						this.tableLoading=false;
						
					},
					(error) => {
						//   alert(JSON.stringify(error));
						this.tableLoading=false;
					}
				);
			},
			// EXcel导出
			ExportEXcel() {
				this.downloadLoading = true;
				console.log('列表数据', this.tablebles);
				this.columnList = [];
				for (var i = 0; i < this.tablebles.length; i++) {
					this.columnList.push({
						Key: this.tablebles[i].prop,
						Value: this.tablebles[i].label
					});
				}
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				if (this.tableData1.length == 0) {
					return this.$message('导出数据不能为空！');
				}
				this.requestUrl = 'api/mall/ebbalance/customer-gold-coin-detail/down-gold-coin-detail-by-customerid';
				this.ApiRequestPostNOMess(this.requestUrl, {
					model: this.columnList,
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					filter: ''
				}).then(
					result => {
						console.log('获取EXcel导出数据', result);
						window.open(result.obj);
						this.downloadLoading = false;
					},
					rej => {
						this.downloadLoading = false;
					}
				);
			}
		},
	};
</script>
<style scoped lang="scss">
  .choose-date {
    width: 406px;
    display: flex;
    align-items: center;
  }
	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		display: none;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.inputBor {
		width: 280px;
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		line-height: 50px;
	}

	::v-deep .el-table .cell {
		white-space: nowrap;
	}

	::v-deep .el-table thead {
		font-size: 13px;
		color: #000000;
		font-weight: 500;
		// text-align: center;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		height: 38px;
		padding: 0px 0px;
		text-align: center;
	}

	::v-deep .is-leaf {
		border-right: 0px solid transparent;
		background-color: #F2F2F2;
	}

	::v-deep .el-table__footer-wrapper tbody td,
	::v-deep .el-table__header-wrapper tbody td {
		height: 38px;
		background-color: #C1CFF7;
	}

	.chooseDate {
		width: 354px;
	}

	.myPointsContent {
		width: 921px;
		min-height: 485px;
		background-color: #FFFFFF;
		// padding:0px 13px;
	}

	.allOrder-btn {
		display: flex;
		// position: relative;
		// left: 290px;
	}

	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-right: 5px;
		text-align: center;
		line-height: 32px;
	}

	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}

	.block {
		margin-left: 260px;
		margin-right: 10px;
	}

	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	/* tab切换标题 */
	.tabTitleRow {
		height: 40px;
		background-color: #F8F9FE;
		border: 1px solid #eee;
		margin-top: 13px;
		box-sizing: border-box;
		margin-bottom: 10px;
	}

	::v-deep .el-table__row {
		height: 36px;
	}

	.tabTitleRow div {
		width: 108px;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #666;
		text-align: center;
		box-sizing: border-box;
	}

	.tabTitleRow div:first-child {
		border-left: none !important;
	}

	.tabTitleRow .checkTitleSty {
		@include themify($themes) {
			color: themed("themes_color");
		}

		font-weight: bold;
		background-color: #fff;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 33px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.date {
		position: relative;
		left: 285px;
	}

	.aaa {
		width: 300px;
		display: flex;
		justify-content: space-evenly;
	}

	.tabOne {
		width: 453px;
		height: 135px;
		background: #fffaf4;
		border-radius: 2px;
		border: 1px solid #F88607;
		margin-top: 10px;
		position: relative;
		float: left;
		margin-right: 5px;
	}

	.tabOne p {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 15px;
		color: #F88607;
		margin: 10px 0px 10px 10px;
	}

	.tabOne img {
		width: 156px;
		height: 104px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.topbgc {
		// width: 917px;
		height: 35px;
		background: #f8f9fe;
		padding: 0 15px;
		margin: 11px auto;
	}

	::v-deep.el-input--suffix .el-input__inner {
		line-height: 32px !important;
		height: 32px !important;
	}

	::v-deep .el-input__icon {
		line-height: 32px !important;
	}

	.billsitems {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #f2f2f2;
	}

	.allOrder-btn .zad {
		/* position: absolute;
		left: 1095px; */
	}

	.allOrder-btn .ad {
		/* position: absolute;
		left: 1175px; */
	}
	.itemIcontment{
		width: 100%;
		height: 67px;
		border-radius: 6px;
		box-shadow: 0px 0px 5px 0px rgba(0,0,153,0.28); 
		margin-bottom: 13px;
		padding: 0 20px;
	}
	.chineseDes{
		text-align:left;
		width:115px;
		height: 24px;
		font-size: 14px;
		font-weight: 400;
		color: #0173fe;
	}
	.coincss{
		font-size: 14px;
		color: #0173fe;
	}
	.coinNumber{
		color:#0173FE;
		font-weight: 700;
		font-size: 18px;
		margin-right: 8px;
	}
	.sumclass{
		width: 100%;
		height: 42px;
		line-height: 42px;
		border-radius: 6px;
		box-shadow: 0px 0px 5px 0px rgba(0,0,153,0.28); 
		text-align: center;
	}
	.activeClass{
		width: 50px;
		height: 32px;
		background-color: #0173FE;
		color:white;
		line-height: 32px;
		text-align: center;
	}
</style>
